<script setup lang="ts">
  const emit = defineEmits<{
    close: []
    edit: []
    retry: [username: string]
  }>()

  const { data: creator } = useStoreCreator().fetchCreator()

  const username = computed(() => creator.value?.insta_account?.insta_username)

  const btn = computed(() => useBrandingButton().extractButton('branding_profil', 'action_btn'))

  const { modalsOpenedCount, isModalFullPage, isMobile } = storeToRefs(useStoreApp())
</script>

<template>
  <ModalApp
    show
    footer-fixed
    :class="{ 'child-modal': modalsOpenedCount > 0 && !isModalFullPage && !isMobile }"
    @close="emit('close')"
  >
    <div class="instagram-not-found">
      <ModalSocialAccountTitle image-url="https://assets.loyoly.io/public/icons/instagram.png">
        {{ $t('Instagram.ModalNotFound.title') }}
      </ModalSocialAccountTitle>

      <div class="instagram-not-found__subtitle">
        <span class="text-primary-heading-2">
          {{ $t('Instagram.ModalNotFound.subtitle') }}
        </span>
        <span class="text-secondary-heading-2">@{{ username }}</span>
      </div>

      <div class="instagram-not-found__description">
        <span class="text-primary-heading-2">
          {{ $t('Instagram.ModalNotFound.description.title') }}
        </span>
        <p class="text-secondary-heading-2">
          {{ $t('Instagram.ModalNotFound.description.text') }}
        </p>
      </div>
    </div>

    <template #footer>
      <ButtonBrandingInverted
        :button-branding="btn"
        @click="emit('edit')"
      >
        {{ $t('Instagram.ModalNotFound.buttons.change') }}
      </ButtonBrandingInverted>
      <ButtonBranding
        :button-branding="btn"
        @click="emit('retry', username!)"
      >
        {{ $t('Instagram.ModalNotFound.buttons.retry') }}
      </ButtonBranding>
    </template>
  </ModalApp>
</template>

<style scoped>
  .instagram-not-found {
    display: grid;
    gap: 30px;

    &__subtitle,
    &__description {
      display: grid;
      gap: 10px;
    }
  }

  .child-modal {
    :deep(.lds-modal__container) {
      left: unset;
      top: unset;
      transform: unset;
    }
  }
</style>
