<script setup lang="ts">
  import SectionProfileEditable from '@/components/section/profile/Editable.vue'
  import { useField, useForm } from 'vee-validate'
  import { toTypedSchema } from '@vee-validate/zod'
  import * as zod from 'zod'
  import { useI18n } from 'vue-i18n'

  const { fetchCreator, updateCreator } = useStoreCreator()
  const { data: creator } = fetchCreator()
  const { mutate } = updateCreator()

  const onEdit = () => {
    firstname.value = creator.value?.first_name || ''
  }

  const { t } = useI18n()
  const validationSchema = toTypedSchema(
    zod.object({
      firstname: zod.string().min(1, { message: t('SectionProfile.errors.firstnameEmpty') })
    })
  )
  const { handleSubmit, errors } = useForm({ validationSchema })
  const { value: firstname } = useField('firstname')

  const editableComponent = ref<InstanceType<typeof SectionProfileEditable> | null>(null)

  const onSubmit = handleSubmit(async (form) => {
    if (!creator.value || !form?.firstname) return

    mutate({ creatorId: creator.value.id, firstname: form.firstname, profil: creator.value.profil })
    editableComponent.value!.setIsEdition(false)
  })
</script>

<template>
  <SectionProfileEditable
    ref="editableComponent"
    field-name="firstname"
    @edit="onEdit"
    @submit="onSubmit"
  >
    <template #label>
      {{ $t('SectionProfile.form.firstname') }}
    </template>
    <template #readonly>
      {{ creator?.first_name || '' }}
    </template>
    <template #form-content>
      <FormInputText
        v-model.trim="firstname"
        name="firstname"
        :placeholder="$t('SectionProfile.form.firstnameExample')"
        :error-message="errors.firstname"
      />
    </template>
  </SectionProfileEditable>
</template>
