<script setup lang="ts">
  const btnAction = computed(() => useBrandingButton().extractButton('branding_profil', 'action_btn'))

  const { data: creator } = useStoreCreator().fetchCreator()

  const tiktokUsername = computed(() => {
    if (!creator.value) return

    return creator.value.tiktok_account?.username
  })

  const isLinked = computed(() => !!tiktokUsername.value)

  const showModalLinked = ref(false)
  const showModalNotLinked = ref(false)

  const onClick = () => {
    useTrack().track('modal-social-media', {
      social_media: 'tiktok',
      social_media_is_connected: isLinked.value,
      email: creator.value!.email
    })
    if (isLinked.value) {
      showModalLinked.value = true
    } else {
      showModalNotLinked.value = true
    }
  }

  const onEditFromModalLinked = () => {
    showModalLinked.value = false
    showModalNotLinked.value = true
  }
</script>

<template>
  <ButtonSocialNetwork
    class="text-primary-heading-2"
    :radius="btnAction.radius"
    :background-color="btnAction.backgroundColor"
    :hover-background-color="btnAction.hoverBackgroundColor"
    :font-size="btnAction.fontSize"
    :text-color="btnAction.textColor"
    :hover-text-color="btnAction.hoverTextColor"
    :weight="btnAction.weight"
    social-network-image-url="https://assets.loyoly.io/public/icons/tiktok.png"
    :username="tiktokUsername"
    @click="onClick"
  >
    {{ $t('SectionProfile.socialNetworks.tiktok') }}
  </ButtonSocialNetwork>
  <TiktokModalLinked
    :show="showModalLinked"
    @close="showModalLinked = false"
    @edit="onEditFromModalLinked"
  />
  <TiktokModalNotLinked
    :show="showModalNotLinked"
    @close="showModalNotLinked = false"
  />
</template>
