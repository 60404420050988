<script setup lang="ts">
  defineProps<{
    show: boolean
  }>()

  const emit = defineEmits<{
    close: []
    link: []
  }>()

  const companyId: string = useRoute().params.companyId as string
  const { fetchTiktokOauth, clearTikTokAccount } = useStoreTiktok()

  const { data: creator } = useStoreCreator().fetchCreator()

  const isSubmitting = ref(false)

  const onSubmit = async () => {
    isSubmitting.value = true
    useTrack().track('click-social-media-verification', {
      social_media: 'tiktok',
      email: creator.value!.email
    })

    const { tiktok_account } = creator.value!
    if (tiktok_account) {
      await clearTikTokAccount(tiktok_account.id)
    }

    const res = await fetchTiktokOauth(parseInt(companyId, 10))
    isSubmitting.value = false
    if (useEmbedded().isEmbedded) {
      navigateExternalUrl(res.oauth_url)
    } else {
      window.location.href = res.oauth_url
    }
  }

  const btn = computed(() => useBrandingButton().extractButton('branding_profil', 'action_btn'))
</script>

<template>
  <ModalApp
    :show
    footer-fixed
    @close="emit('close')"
  >
    <div class="tiktok-not-linked">
      <ModalSocialAccountTitle image-url="https://assets.loyoly.io/public/icons/tiktok.png">
        {{ $t('Tiktok.ModalNotLinked.title') }}
      </ModalSocialAccountTitle>

      <span class="text-primary-heading-2">
        {{ $t('Tiktok.ModalNotLinked.subtitle') }}
      </span>

      <p class="text-secondary-heading-2">
        {{ $t('Tiktok.ModalNotLinked.description') }}
      </p>
    </div>

    <template #footer>
      <ButtonBranding
        :button-branding="btn"
        :loading="isSubmitting"
        @click="onSubmit"
      >
        {{ $t('Tiktok.ModalNotLinked.button') }}
      </ButtonBranding>
    </template>
  </ModalApp>
</template>

<style scoped>
  .tiktok-not-linked {
    display: grid;
    gap: 20px;
  }
</style>
