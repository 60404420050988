import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import type { ApiErrorClass } from '~/types/apiError'
import type { Challenge } from '~/types/challenge'
import { Buffer } from 'buffer'

export const useStoreChallenge = defineStore('challenge', () => {
  const { isAuthenticated } = storeToRefs(useStoreAuth())
  const queryClient = useQueryClient()
  const { cloudinaryUrl } = useRuntimeConfig().public

  const getChallenges = (companyId: number) => {
    return useQuery({
      queryKey: ['listChallenges', companyId, isAuthenticated],
      queryFn: async () => {
        return (await useNuxtApp().$api(`/api/creator/company/${companyId}/challenges/`)) as Challenge[]
      },
      staleTime: 1800000 // 30 minutes
    })
  }

  const getLoyaltyChallenges = (companyId: number) => {
    return useQuery({
      queryKey: ['listLoyaltyChallenges', companyId, isAuthenticated],
      queryFn: async () => {
        return (await useNuxtApp().$api(`/api/creator/company/${companyId}/loyalty/challenges/`)) as Challenge[]
      },
      staleTime: 1800000 // 30 minutes
    })
  }

  const getCampaignChallenges = (companyId: number, campaignId: number) => {
    return useQuery({
      queryKey: ['listCampaignChallenges', companyId, isAuthenticated, campaignId],
      queryFn: async () => {
        return (await useNuxtApp().$api(`/api/creator/company/${companyId}/challenges/`, {
          params: { campaign_id: campaignId }
        })) as Challenge[]
      },
      staleTime: 1800000 // 30 minutes
    })
  }

  const sendChallengeResponse = () => {
    return useMutation({
      mutationFn: async ({ formData }: { formData: FormData }) => {
        return await useNuxtApp().$api(`/api/challenge_creators/`, {
          method: 'POST',
          body: formData
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['listChallenges'] })
        queryClient.invalidateQueries({ queryKey: ['listCampaignChallenges'] })
      },
      onError: (error: ApiErrorClass) => {
        return error
      }
    })
  }

  const uploadMediaCloudinary = async (data: File | string) => {
    const formDataUploadImage = new FormData()
    formDataUploadImage.append('upload_preset', 'im9t3d3t')
    formDataUploadImage.append('tags', 'direct_upload')
    formDataUploadImage.append('file', data)

    const callCloudinary = $fetch.create({
      baseURL: cloudinaryUrl,
      method: 'POST'
    })

    const resCloudinary = (await callCloudinary('', { body: formDataUploadImage })) as { secure_url: string }
    return resCloudinary.secure_url as string
  }

  const uploadMediaGcs = async (data: File | string) => {
    let base
    // data can be a File (for videos) or a base64 string (for images)
    if (typeof data !== 'string') {
      // video
      base = (await getBase64(data)) as string
    } else {
      // image
      base = data as string
    }
    const filedata = base.split(';')[0]
    const extension = filedata.split('/')[1]
    const file_type = filedata.split(':')[1]
    const resUploadUrl = (await useNuxtApp().$api(`/api/creator/challenge-creators/upload-url/`, {
      method: 'GET',
      params: { extension }
    })) as { signed_url: string; remote_url: string }

    const callGcs = $fetch.create({
      baseURL: resUploadUrl.signed_url,
      method: 'PUT',
      headers: { 'Content-Type': file_type }
    })
    await callGcs('', {
      method: 'PUT',
      body: Buffer.from(base.replace(/^data:\w+\/\w+;base64,/, ''), 'base64')
    })
    return resUploadUrl.remote_url
  }

  return {
    getChallenges,
    getCampaignChallenges,
    getLoyaltyChallenges,
    sendChallengeResponse,
    uploadMediaCloudinary,
    uploadMediaGcs
  }
})
