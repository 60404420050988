<script setup lang="ts">
  import type { ClaimedReward } from '~/types/reward'

  const dayjs = useDayjs()
  const { companyId } = useRoute().params
  const { data: claimedRewards } = useStoreReward().getClaimedRewards(parseInt(companyId as string))
  const { data: creator } = useStoreCreator().fetchCreator()
  const { getRewardFormattedText } = useFormattedText()
  const { t } = useI18n()

  defineProps<{
    show: boolean
  }>()

  const emit = defineEmits<{
    close: []
  }>()

  const showRewardClaimModal = ref(false)
  const selectedReward = ref<ClaimedReward | null>(null)

  onMounted(() => {
    useTrack().track('modal-rewards-claimed', {
      email: creator.value?.email ?? '',
      nbr_reward_claimed: claimedRewards.value?.length ?? 0
    })
  })

  const claimedRewardsSorted = computed((): ClaimedReward[] => {
    if (!claimedRewards.value) return []
    return [...claimedRewards.value].reverse()
  })

  const section = computed(() => {
    const { brandings } = useStoreBranding()

    if (!brandings) return

    const { extractTypography } = useBrandingTypography()
    const { extractButton } = useBrandingButton()

    return {
      card: brandings.branding_reward.card,
      rewardName: extractTypography('branding_reward', 'reward_text', 'primary', true),
      rewardPoints: extractTypography('branding_reward', 'point_text', 'secondary', true),
      actionButton: extractButton('branding_reward', 'action_btn')
    }
  })

  const getActionBtnText = (claimedReward: ClaimedReward) => {
    if (dayjs(claimedReward.expiration_date) < dayjs()) {
      return t('SectionRewardsModalMyRewards.expired')
    }
    return t('SectionRewardsModalMyRewards.use')
  }

  const onClick = (claimedReward: ClaimedReward) => {
    useTrack().track('click-reward-details', {
      email: creator.value?.email ?? '',
      reward_type: claimedReward.reward.promo_type,
      reward_id: claimedReward.id
    })
    selectedReward.value = claimedReward
    showRewardClaimModal.value = true
  }
</script>

<template>
  <ModalApp
    v-if="section"
    class="my-rewards-modal"
    :show
    full-page
    no-footer
    @close="emit('close')"
  >
    <h1 class="text-primary-heading-1">
      {{ $t('SectionRewardsModalMyRewards.title') }} ({{ claimedRewardsSorted.length }})
    </h1>
    <div
      v-if="claimedRewardsSorted.length > 0"
      class="my-rewards-modal__grid"
    >
      <CardReward
        v-for="claimedReward in claimedRewardsSorted"
        :id="claimedReward.id"
        :key="claimedReward.id"
        class="card-claimedreward"
        :background-color="section.card.background_color"
        :hover-background-color="section.card.hover_background_color"
        :border-color="section.card.border_color"
        :border-radius="section.card.radius"
        :reward-points="section.rewardPoints"
        :card-button="section.actionButton"
        :image-url="claimedReward.reward.picture_url_1"
        :reward-name="section.rewardName"
        @click="onClick(claimedReward)"
      >
        <template
          v-if="claimedReward.reward.threshold > 0"
          #points
        >
          {{ $t('Points', claimedReward.reward.threshold) }}
        </template>
        <template #name>
          {{ getRewardFormattedText(claimedReward.reward.name, claimedReward.reward.amount, 'local_currency_reward') }}
        </template>
        <template #button>{{ getActionBtnText(claimedReward) }}</template>
      </CardReward>
    </div>
    <SectionEmpty
      v-else
      class="my-rewards-modal__empty"
    >
      {{ $t('SectionRewardsModalMyRewards.empty') }}
      <div class="my-rewards-modal__empty-tip">
        {{ $t('SectionRewardsModalMyRewards.emptyTip') }}
      </div>
    </SectionEmpty>
    <SectionRewardsModalClaim
      v-if="selectedReward"
      :claimed-reward="selectedReward"
      :show="showRewardClaimModal"
      @close="showRewardClaimModal = false"
    />
  </ModalApp>
</template>

<style scoped>
  .my-rewards-modal {
    &__grid {
      display: grid;
      place-content: center;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      @media (min-width: 480px) {
        column-gap: 40px;
      }

      @media (min-width: 768px) {
        gap: 70px;
      }

      @media (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 42.5px;
      }

      @media (min-width: 1440px) {
        gap: 70px;
      }
    }

    &__empty {
      text-align: center;
      display: inline-block;
    }
    &__empty-tip {
      margin-top: 1.5rem;
    }
  }
</style>
