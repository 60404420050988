<script setup lang="ts">
  import SectionProfileEditable from '@/components/section/profile/Editable.vue'
  import { useField, useForm } from 'vee-validate'
  import { toTypedSchema } from '@vee-validate/zod'
  import * as zod from 'zod'
  import { useI18n } from 'vue-i18n'

  const { fetchCreator, updateCreator } = useStoreCreator()
  const { data: creator } = fetchCreator()
  const { mutate } = updateCreator()

  const onEdit = () => {
    email.value = creator.value?.email || ''
  }

  const { t } = useI18n()
  const validationSchema = toTypedSchema(
    zod.object({
      email: zod
        .string()
        .min(1, { message: t('SectionProfile.errors.emailEmpty') })
        .email({ message: t('SectionProfile.errors.email') })
    })
  )
  const { handleSubmit, errors } = useForm({ validationSchema })
  const { value: email } = useField('email')

  const editableComponent = ref<InstanceType<typeof SectionProfileEditable> | null>(null)

  const onSubmit = handleSubmit(async (form) => {
    if (!creator.value || !form?.email) return

    mutate({ creatorId: creator.value.id, email: form.email, profil: creator.value.profil })
    editableComponent.value!.setIsEdition(false)
  })
</script>

<template>
  <SectionProfileEditable
    ref="editableComponent"
    field-name="email"
    @edit="onEdit"
    @submit="onSubmit"
  >
    <template #label>
      {{ $t('SectionProfile.form.email') }}
    </template>
    <template #readonly>
      {{ creator?.email || '' }}
    </template>
    <template #form-content>
      <FormInputText
        v-model.trim="email"
        name="email"
        :placeholder="$t('SectionProfile.form.emailExample')"
        :error-message="errors.email"
      />
    </template>
  </SectionProfileEditable>
</template>
