<script setup lang="ts">
  const companyId = useRoute().params.companyId as string

  const { updateCompanyCreator, getCompanyCreator } = useStoreCreator()
  const { data: companyCreator } = getCompanyCreator(parseInt(companyId))
  const { mutate } = updateCompanyCreator()

  defineProps<{
    show: boolean
  }>()

  const emit = defineEmits<{
    close: []
  }>()

  const optins = computed(() => {
    return {
      optinTransactional: companyCreator?.value?.optin_transactional || false,
      optinMarketing: companyCreator?.value?.optin_marketing || false
    }
  })

  const onChange = () => {
    mutate({
      creatorId: companyCreator.value!.id,
      optinTransactional: optins.value.optinTransactional,
      optinMarketing: optins.value.optinMarketing
    })
  }
</script>

<template>
  <ModalApp
    class="notifications-modal"
    :show
    no-footer
    @close="emit('close')"
  >
    <h1 class="text-primary-heading-1">{{ $t('Modal.Notifications.title') }}</h1>
    <h2 class="text-primary-heading-2">{{ $t('Modal.Notifications.information.title') }}</h2>
    <p class="text-secondary-body">{{ $t('Modal.Notifications.information.text') }}</p>
    <FormGroup>
      <FormCheckbox
        v-model="optins.optinTransactional"
        @change="onChange"
      >
        {{ $t('Modal.Notifications.communication.optinTransactional') }}
      </FormCheckbox>
      <FormCheckbox
        v-model="optins.optinMarketing"
        @change="onChange"
      >
        {{ $t('Modal.Notifications.communication.optinMarketing') }}
      </FormCheckbox>
    </FormGroup>
  </ModalApp>
</template>
