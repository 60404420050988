<script setup lang="ts">
  const emit = defineEmits<{
    close: []
    error: []
    success: []
  }>()

  const { setHasToWaitInstagramLinked, fetchCreator } = useStoreCreator()

  setHasToWaitInstagramLinked(true)

  const { isFetching, isError, isSuccess } = fetchCreator()

  watch(isError, (value) => {
    if (value) {
      emit('error')
    }
  })

  watch(
    isFetching,
    (newVal, oldVal) => {
      if (!newVal && oldVal) {
        if (isSuccess.value) {
          emit('success')
        }
      }
    },
    {
      immediate: true
    }
  )

  onBeforeUnmount(() => setHasToWaitInstagramLinked(false))

  const { modalsOpenedCount, isModalFullPage, isMobile } = storeToRefs(useStoreApp())
</script>

<template>
  <ModalApp
    show
    no-footer
    :class="{ 'child-modal': modalsOpenedCount > 0 && !isModalFullPage && !isMobile }"
    @close="emit('close')"
  >
    <div class="instagram-searching">
      <ModalSocialAccountTitle image-url="https://assets.loyoly.io/public/icons/instagram.png">
        {{ $t('Instagram.ModalNotLinked.title') }}
      </ModalSocialAccountTitle>
      <div class="instagram-searching__content">
        <IconLoader
          color="#000"
          width="48px"
          height="48px"
        />
        <span class="text-primary-heading-1">
          {{ $t('Instagram.ModalSearching.title') }}
        </span>
      </div>
    </div>
  </ModalApp>
</template>

<style scoped>
  .instagram-searching {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;

    &__content {
      display: flex;
      flex-direction: column;
      gap: 30px;
      align-items: center;
      justify-content: center;
    }
  }

  :deep(.lds-modal__content) {
    height: 100%;
  }

  .child-modal {
    :deep(.lds-modal__container) {
      left: unset;
      top: unset;
      transform: unset;
    }
  }
</style>
