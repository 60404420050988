<script setup lang="ts">
  import InstagramAccountEdition from '@/components/instagram/AccountEdition.vue'

  const btnAction = computed(() => useBrandingButton().extractButton('branding_profil', 'action_btn'))

  const { data: creator } = useStoreCreator().fetchCreator()

  const instagramUsername = computed(() => {
    if (!creator.value) return

    return creator.value.insta_account?.insta_username
  })

  const instagramAccountEdition = ref<InstanceType<typeof InstagramAccountEdition> | null>(null)
</script>

<template>
  <ButtonSocialNetwork
    class="text-primary-heading-2"
    :radius="btnAction.radius"
    :background-color="btnAction.backgroundColor"
    :hover-background-color="btnAction.hoverBackgroundColor"
    :font-size="btnAction.fontSize"
    :text-color="btnAction.textColor"
    :hover-text-color="btnAction.hoverTextColor"
    :weight="btnAction.weight"
    social-network-image-url="https://assets.loyoly.io/public/icons/instagram.png"
    :username="instagramUsername"
    @click="instagramAccountEdition!.handleAccountEdition()"
  >
    {{ $t('SectionProfile.socialNetworks.instagram') }}
  </ButtonSocialNetwork>
  <InstagramAccountEdition ref="instagramAccountEdition" />
</template>
