<script setup lang="ts">
  import SectionProfileEditable from '@/components/section/profile/Editable.vue'
  import { useField, useForm } from 'vee-validate'
  import { toTypedSchema } from '@vee-validate/zod'
  import * as zod from 'zod'
  import { useI18n } from 'vue-i18n'

  const isCaseChecked = computed((): boolean => isPasswordCaseChecked(newPassword.value as string))
  const isLengthChecked = computed((): boolean => isPasswordLengthChecked(newPassword.value as string))
  const isNumberLetterChecked = computed((): boolean => isPasswordNumberLetterChecked(newPassword.value as string))
  const isSpecialCharChecked = computed((): boolean => isPasswordSpecialCharChecked(newPassword.value as string))

  const { t } = useI18n()
  const validationSchema = toTypedSchema(
    zod.object({
      currentPassword: zod.string().min(1, { message: t('SectionProfile.errors.passwordCurrent') }),
      newPassword: zod
        .string()
        .refine((value) => isPasswordValid(value), { message: t('SectionProfile.errors.passwordNew') }),
      confirmPassword: zod
        .string()
        .min(1, { message: t('SectionProfile.errors.passwordConfirm') })
        .refine((value) => value && value === newPassword.value, {
          message: t('SectionProfile.errors.passwordConfirm')
        })
    })
  )
  const { handleSubmit, errors, resetField, resetForm, setErrors } = useForm({ validationSchema })
  const { value: currentPassword } = useField('currentPassword')
  const { value: newPassword } = useField('newPassword')
  const { value: confirmPassword } = useField('confirmPassword')

  const onEdit = () => {
    resetField('currentPassword')
    resetField('newPassword')
    resetField('confirmPassword')
    resetForm()
  }

  const editableComponent = ref<InstanceType<typeof SectionProfileEditable> | null>(null)
  const { fetchCreator, updatePassword } = useStoreCreator()
  const { data: creator } = fetchCreator()

  const onSubmit = handleSubmit(async ({ currentPassword, newPassword, confirmPassword }) => {
    const creatorId = creator.value!.id
    try {
      await updatePassword({ creatorId, currentPassword, newPassword, confirmPassword })
      editableComponent.value!.setIsEdition(false)
    } catch (error) {
      setErrors({ currentPassword: t('SectionProfile.errors.passwordCurrentWrong') })
    }
  })
</script>

<template>
  <SectionProfileEditable
    ref="editableComponent"
    class="password-edition"
    field-name="firstname"
    @edit="onEdit"
    @submit="onSubmit"
  >
    <template #label>
      {{ $t('SectionProfile.form.password') }}
    </template>
    <template #readonly>
      {{ $t('SectionProfile.form.passwordExample') }}
    </template>
    <template #form-content>
      <FormGroup>
        <FormLabel>{{ $t('SectionProfile.form.passwordCurrent') }} *</FormLabel>
        <FormInputPassword
          v-model="currentPassword"
          class="password-edition__form--input"
          name="currentPassword"
          :placeholder="$t('SectionProfile.form.passwordExample')"
          :error-message="errors.currentPassword"
        />
      </FormGroup>

      <FormGroup>
        <FormLabel>{{ $t('SectionProfile.form.passwordNew') }} *</FormLabel>
        <FormInputPassword
          v-model="newPassword"
          class="password-edition__form--input"
          name="newPassword"
          :placeholder="$t('SectionProfile.form.passwordExample')"
          :error-message="errors.newPassword"
        />
      </FormGroup>

      <FormGroup>
        <FormLabel>{{ $t('SectionProfile.form.passwordConfirm') }} *</FormLabel>
        <FormInputPassword
          v-model="confirmPassword"
          class="password-edition__form--input"
          name="confirmPassword"
          :placeholder="$t('SectionProfile.form.passwordExample')"
          :error-message="errors.confirmPassword"
        />
      </FormGroup>

      <PasswordRulesCheckGrid
        class="password-edition__form--rules"
        :is-length-checked="isLengthChecked"
        :is-number-letter-checked="isNumberLetterChecked"
        :is-case-checked="isCaseChecked"
        :is-special-char-checked="isSpecialCharChecked"
      />
    </template>
  </SectionProfileEditable>
</template>

<style scoped>
  .password-edition {
    &__form {
      &--rules {
        display: grid;
        place-content: center;
        grid-template-columns: auto auto;
        row-gap: 10px;
        column-gap: 5px;
      }

      &--input {
        width: 100%;
      }
    }
  }

  :deep(.setting__form) {
    grid-template-columns: unset;
  }
</style>
