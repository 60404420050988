<script setup lang="ts">
  const { $i18n } = useNuxtApp()

  const locales = computed(() => $i18n.locales.value.map((locale) => ({ value: locale.code })))

  const currentLang = computed(() => locales.value.find((locale) => $i18n.locale.value === locale.value)?.value || 'en')

  const selectedLang = ref(currentLang.value)

  const onLangUpdate = () => $i18n.setLocale(selectedLang.value)

  const getIcon = (code: string) => defineAsyncComponent(() => import(`@/assets/icons/locales/${code}.svg`))
</script>

<template>
  <FormSelect
    v-model="selectedLang"
    class="select-lang"
    :items="locales"
    @update:model-value="onLangUpdate"
  >
    <component
      :is="getIcon(currentLang)"
      class="flag-icon"
    />
    <template #item="{ item }">
      <component
        :is="getIcon(item.value)"
        class="flag-icon"
      />
    </template>
  </FormSelect>
</template>

<style scoped>
  .select-lang {
    width: 76px;

    @media (min-width: 768px) {
      width: 98px;
    }

    :deep(.lds-form-select__button),
    :deep(.lds-form-select__content) {
      width: 76px;

      @media (min-width: 768px) {
        width: 98px;
      }
    }

    .flag-icon {
      margin-top: 5px;
    }
  }
</style>
