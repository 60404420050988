<script setup lang="ts">
  const buttonActvities = computed(() => useBrandingButton().extractButton('branding_step', 'activity_btn'))

  const { data: creator } = useStoreCreator().fetchCreator()

  const showModal = ref(false)

  const onClick = () => {
    useTrack().track('click-recent-activities', {
      email: creator.value?.email ?? ''
    })
    showModal.value = true
  }
</script>

<template>
  <ButtonBranding
    :button-branding="buttonActvities"
    class="btn-activity"
    @click="onClick"
  >
    {{ buttonActvities.text }}
  </ButtonBranding>
  <SectionStepsModalActivities
    v-if="showModal"
    @close="showModal = false"
  />
</template>
<style scoped>
  @media (min-width: 768px) {
    .btn-activity {
      width: 320px;
    }
  }
</style>
