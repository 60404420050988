<script setup lang="ts">
  import type { TypographyBranding } from '~/types/branding'

  defineProps<{
    show: boolean
    title: TypographyBranding
  }>()

  const emit = defineEmits<{
    close: []
  }>()
</script>

<template>
  <ModalApp
    :show
    full-page
    no-footer
    @close="emit('close')"
  >
    <TypographyBase
      :id="title.id"
      tag="h1"
      class="text-primary-heading-2"
      :font-weight="title.fontWeight"
      :font-size="title.fontSize"
      :color="title.color"
      :font-family="title.fontFamily"
    >
      {{ title.text }}
    </TypographyBase>
    <slot />
  </ModalApp>
</template>
