<template>
  <LoaderSkeleton>
    <div class="section-skeleton-title" />
  </LoaderSkeleton>
</template>

<style scoped>
  .section-skeleton-title {
    width: 255px;
    height: 20px;
    background-color: #e1e1e1;

    @media (min-width: 768px) {
      width: 448px;
    }
  }
</style>
