export function useModalResize() {
  const update = async () => {
    await nextTick()
    if (useStoreApp().domModalsCount === 0 || useStoreApp().isMobile) return

    const allModals = document.querySelectorAll('.lds-modal__mask')
    const count = allModals.length

    if (count === 0) return

    await new Promise((resolve) => setTimeout(resolve, 350)) // 300 ms the animation delay of accordion and 50 of security

    const lastModalOnScreen = document.querySelectorAll('.lds-modal__container')[count - 1] as HTMLElement
    const height = Math.max(lastModalOnScreen.scrollHeight, lastModalOnScreen.offsetHeight)
    window.parent.postMessage({ height, isWeWiink: true }, '*')
  }

  return {
    update
  }
}
