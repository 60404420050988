<script setup lang="ts">
  const emit = defineEmits<{
    click: []
  }>()
</script>

<template>
  <button
    class="button-show-all text-secondary-small"
    @click="emit('click')"
  >
    <slot />
    <IconChevronRight
      name="chevron-right"
      width="4px"
      height="8px"
    />
  </button>
</template>

<style scoped>
  .button-show-all {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 20px;
    padding-left: 0;
    padding-right: 4px;
    flex-shrink: 0;
    cursor: pointer;
  }
</style>
