<script setup lang="ts">
  const { t } = useI18n()
  defineProps<{
    refusalReasons: number[]
  }>()

  const translateReason = new Map()
  translateReason.set(0, t('SectionChallenges.ModalMissionDetail.refusalReasonCondition'))
  translateReason.set(1, t('SectionChallenges.ModalMissionDetail.refusalReasonQuality'))
  translateReason.set(2, t('SectionChallenges.ModalMissionDetail.refusalReasonContent'))
  translateReason.set(3, t('SectionChallenges.ModalMissionDetail.refusalReasonSound'))
</script>

<template>
  <div class="text-primary-heading-2">
    {{ $t('SectionChallenges.ModalMissionDetail.missionInvalidTitle') }}
  </div>
  <div class="text-secondary-heading-2">
    {{ $t('SectionChallenges.ModalMissionDetail.missionInvalidDescription') }}
  </div>
  <div class="text-secondary-heading-2">
    {{ $t('SectionChallenges.ModalMissionDetail.missionInvalidReasons') }}
  </div>
  <ul>
    <li
      v-for="(reason, index) in refusalReasons"
      :key="`refusal-reason-${index}`"
      class="mission-status-invalid__list"
    >
      {{ translateReason.has(reason) ? translateReason.get(reason) : reason }}
    </li>
  </ul>
</template>
<style scoped>
  div,
  ul {
    margin-top: 10px;
  }
</style>
