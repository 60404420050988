<script setup lang="ts">
  import type { SocialAccountInfo } from '~/types/social-account'

  const props = defineProps<{
    account: SocialAccountInfo
  }>()

  const imgSrc = computed(() => {
    // cf. https://vitejs.dev/guide/assets.html#new-url-url-import-meta-url (necessary for dynamic src import)
    if (!props.account.profilePictureUrl) return new URL(`~/assets/icons/default-avatar.svg`, import.meta.url).href
    return props.account.profilePictureUrl
  })
</script>

<template>
  <div class="social-account-info">
    <ImageBase
      :src="imgSrc"
      class="social-account-info--image"
      width="80px"
      height="80px"
    />
    <div>
      <span class="social-account-info--text text-secondary-heading-2">@{{ account.username }}</span>
      <span
        v-if="account.followersCount"
        class="social-account-info--text text-secondary-heading-2"
      >
        {{ $t('Modal.SocialAccount.Info.followers', account.followersCount) }}
      </span>
      <span
        v-if="account.isPrivate !== null"
        class="social-account-info--text text-primary-heading-2"
      >
        {{ account.isPrivate ? $t('Modal.SocialAccount.Info.private') : $t('Modal.SocialAccount.Info.public') }}
      </span>
    </div>
  </div>
</template>

<style scoped>
  .social-account-info {
    display: flex;
    gap: 20px;
    align-items: center;

    &--image {
      border-radius: 10px;
    }

    &--text {
      display: block;
    }
  }
</style>
