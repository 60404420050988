<script setup lang="ts">
  import type { VipLevel } from '~/types/vip'

  const { brandings } = useStoreBranding()
  const { $i18n } = useNuxtApp()
  const { companyId } = useRoute().params
  const { data: companyCreator } = useStoreCreator().getCompanyCreator(parseInt(companyId as string))
  const { getMoneyFormattedText } = useFormattedText()

  const props = defineProps<{
    vipLevels: VipLevel[]
  }>()

  const section = computed(() => {
    const { brandings } = useStoreBranding()
    if (!brandings?.branding_vip) return

    const { extractTypography } = useBrandingTypography()

    return {
      textNextLevel: extractTypography('branding_vip', 'text_next_level', 'secondary'),
      textFinalLevel: extractTypography('branding_vip', 'text_final_level', 'secondary'),
      textPoint: extractTypography('branding_vip', 'text_point', 'secondary'),
      progressBackground: brandings.branding_vip.progress_background,
      progressColor: brandings.branding_vip.progress_color,
      progressPoint: brandings.branding_vip.progress_point
    }
  })

  const currentLevel = computed((): VipLevel | undefined => {
    const levelId = companyCreator?.value?.level_id
    return props.vipLevels.find((level) => level.id === levelId)
  })

  const nextLevel = computed((): VipLevel | undefined => {
    if (!currentLevel.value) {
      return Array.from(props.vipLevels).sort((levelA, levelB) =>
        levelA.points_objective >= levelB.points_objective ? 1 : -1
      )[0]
    }

    return Array.from(props.vipLevels)
      .sort((levelA, levelB) => (levelA.points_objective <= levelB.points_objective ? -1 : 1))
      .find((level) => level.points_objective > currentLevel.value!.points_objective)
  })

  const nextLevelTextMoney = computed((): string => {
    if (!brandings?.branding_vip || !nextLevel.value) return ''

    return brandings.branding_vip.text_next_level.text
      .replace(
        '{{points}}',
        `<span class="bold">
                ${getMoneyFormattedText(nextLevel.value?.points_objective - (companyCreator?.value?.vip_points || 0), false)}</span>`
      )
      .replace('{{tier_name}}', nextLevel.value?.name)
  })

  const nextLevelTextPoint = computed((): string => {
    if (!brandings?.branding_vip || !nextLevel.value) return ''

    return brandings.branding_vip.text_next_level.text
      .replace(
        '{{points}}',
        `<span class="bold">${$i18n.n(nextLevel.value?.points_objective - (companyCreator?.value?.vip_points || 0))} ${$i18n.t(
          'SectionVipTiersProgress.points'
        )}</span>`
      )
      .replace('{{tier_name}}', nextLevel.value?.name)
  })

  const lastLevelText = computed((): string => {
    if (!brandings?.branding_vip) return ''
    return brandings.branding_vip.text_final_level.text.replace('{{tier_name}}', lastLevel.value?.name)
  })

  const lastLevel = computed((): VipLevel => {
    return Array.from(props.vipLevels).sort((levelA, levelB) =>
      levelA.points_objective >= levelB.points_objective ? -1 : 1
    )[0]
  })

  const isLastLevel = computed((): boolean => {
    return currentLevel.value?.id === lastLevel.value?.id
  })

  const progressWidth = computed((): number => {
    if (isLastLevel.value) {
      return 100
    }

    const currentLevelPointsObjective = currentLevel.value?.points_objective || 0
    const nextLevelPointsObjective = nextLevel.value?.points_objective || 0

    let currentPoint = (companyCreator?.value?.vip_points || 0) - currentLevelPointsObjective
    if (currentPoint < 0) {
      currentPoint = companyCreator?.value?.vip_points || 0
    }
    let nextLevelPoint = nextLevelPointsObjective
    if (nextLevel.value?.id !== currentLevel.value?.id) {
      nextLevelPoint = nextLevelPointsObjective - currentLevelPointsObjective
    }

    return (currentPoint / nextLevelPoint) * 100
  })

  const isAccessModeMoney = computed(() => {
    // mode is the same for each level
    const level = currentLevel.value ?? nextLevel.value
    return level?.mode === 2
  })
</script>

<template>
  <div
    v-if="section"
    class="vip-progress"
  >
    <div class="vip-progress__info-container">
      <TypographyBase
        v-if="!isLastLevel"
        :id="section.textNextLevel.id"
        tag="span"
        class="vip-progress__info-container--info-next"
        :font-weight="section.textNextLevel.fontWeight"
        :font-size="section.textNextLevel.fontSize"
        :color="section.textNextLevel.color"
        :text-align="section.textNextLevel.textAlign"
        :font-family="section.textNextLevel.fontFamily"
      >
        <span v-dompurify-html="isAccessModeMoney ? nextLevelTextMoney : nextLevelTextPoint"></span>
      </TypographyBase>
      <TypographyBase
        v-else
        :id="section.textFinalLevel.id"
        tag="span"
        class="vip-progress__info-container--info-final"
        :font-weight="section.textFinalLevel.fontWeight"
        :font-size="section.textFinalLevel.fontSize"
        :color="section.textFinalLevel.color"
        :text-align="section.textFinalLevel.textAlign"
        :font-family="section.textFinalLevel.fontFamily"
      >
        {{ lastLevelText }}
      </TypographyBase>
    </div>
    <div class="vip-progress__progress-wrapper">
      <div class="vip-progress__progress-wrapper--container-level">
        <TypographyBase
          :id="section.textPoint.id"
          tag="span"
          class="vip-progress__progress-wrapper--container-level--text"
          :font-weight="section.textPoint.fontWeight"
          :font-size="section.textPoint.fontSize"
          :color="section.textPoint.color"
          :font-family="section.textPoint.fontFamily"
        >
          <span>
            {{ $t('SectionVipTiersProgress.you') }}
          </span>
          <span
            v-if="lastLevel.mode === 2"
            class="vip-progress__progress-wrapper--container-level--text-pts"
          >
            {{ getMoneyFormattedText(companyCreator?.vip_points || 0, false) }}
          </span>
          <span
            v-else
            class="vip-progress__progress-wrapper--container-level--text-pts"
          >
            {{ $n(companyCreator?.vip_points || 0) }}&nbsp;{{ $t('SectionVipTiersProgress.points') }}
          </span>
        </TypographyBase>

        <TypographyBase
          v-if="nextLevel"
          :id="section.textPoint.id"
          tag="span"
          class="vip-progress__progress-wrapper--container-level--text"
          :font-weight="section.textPoint.fontWeight"
          :font-size="section.textPoint.fontSize"
          :color="section.textPoint.color"
          :font-family="section.textPoint.fontFamily"
        >
          <span>{{ nextLevel?.name }}</span>
          <span
            v-if="isAccessModeMoney"
            class="vip-progress__progress-wrapper--container-level--text-pts"
          >
            {{ getMoneyFormattedText(nextLevel?.points_objective || 0, false) }}
          </span>
          <span
            v-else
            class="vip-progress__progress-wrapper--container-level--text-pts"
          >
            {{ nextLevel?.points_objective || 0 }}&nbsp;{{ $t('SectionVipTiersProgress.points') }}
          </span>
        </TypographyBase>
      </div>
      <ProgressBarBase
        :background-color="section.progressBackground"
        :progress-color="section.progressColor"
        :dot-color="section.progressPoint"
        :progress="progressWidth"
      />
    </div>
  </div>
</template>

<style scoped>
  .vip-progress {
    display: grid;
    gap: 40px;
    align-items: center;

    @media (width >= 809px) {
      grid-template-columns: 1.5fr 2.5fr;
      gap: 40px;
    }

    &__info-container {
      display: flex;
      width: 100%;

      &--info-next,
      &--info-final {
        width: 100%;
      }
    }

    &__progress-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &--container-level {
        display: flex;
        flex-direction: row;
        span {
          display: flex;
          flex-direction: column;
        }
        &--text-pts {
          font-weight: bold;
        }
        &--text:last-child {
          width: auto;
          margin-left: auto;
        }
      }
    }
  }
</style>
