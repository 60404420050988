<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import type { ApiErrorClass } from '~/types/apiError'

  withDefaults(
    defineProps<{
      fromChallenge?: boolean
    }>(),
    { fromChallenge: false }
  )

  const emit = defineEmits<{
    'goto-challenge': []
  }>()

  const { fetchCreator } = useStoreCreator()
  const { data: creator } = fetchCreator()
  const { clearInstagramAccount, setInstagramAccount } = useStoreInstagram()
  const { mutateAsync } = setInstagramAccount()

  const instagramUsername = computed(() => {
    if (!creator.value) return

    return creator.value.insta_account?.insta_username
  })

  const hasAccount = computed(() => !!instagramUsername.value)
  const hasAccountInfo = computed(() => hasAccount.value && creator.value!.insta_account.is_private !== null)

  const showModalLinked = ref(false)
  const showModalSearching = ref(false)
  const showModalNotLinked = ref(false)
  const showModalNotFound = ref(false)

  const handleAccountEdition = () => {
    useTrack().track('modal-social-media', {
      social_media: 'instagram',
      social_media_is_connected: hasAccountInfo.value,
      email: creator.value!.email
    })

    if (!hasAccount.value) {
      showModalNotLinked.value = true
      return
    }

    if (hasAccountInfo.value) {
      showModalLinked.value = true
    } else {
      showModalNotFound.value = true
    }
  }

  const onEdit = async () => {
    useTrack().track('click-social-media-modify', {
      social_media: 'instagram',
      email: creator.value!.email
    })

    showModalLinked.value = false
    await nextTick()
    showModalNotFound.value = false
    await nextTick()
    showModalNotLinked.value = true
  }

  const apiError = ref<string | undefined>()
  const { te, t } = useI18n()

  const setAccount = async (username: string) => {
    showModalLinked.value = false
    await nextTick()
    showModalNotFound.value = false
    apiError.value = undefined

    try {
      const { insta_account } = creator.value!
      if (insta_account) {
        await clearInstagramAccount(insta_account.id)
      }

      const instaUsername = username.startsWith('@') ? username.slice(1) : username
      await mutateAsync({ instaUsername, creatorId: creator.value!.id })
      showModalNotLinked.value = false
      await nextTick()
      showModalSearching.value = true
    } catch (error) {
      const { response } = error as ApiErrorClass
      if (response && response._data) {
        const errorKey = Object.values(response._data)[0]
        const transKey = `ApiErrors.${errorKey}`
        apiError.value = te(transKey) ? t(transKey) : t('ApiErrors.generic')
      }
    }
  }

  const onSearchingError = async () => {
    showModalSearching.value = false
    await nextTick()
    showModalNotFound.value = true
  }

  const onSearchingSuccess = async () => {
    showModalSearching.value = false
    await nextTick()
    showModalLinked.value = true
  }

  const onGoToChallenge = async () => {
    showModalLinked.value = false
    await nextTick()
    emit('goto-challenge')
  }

  defineExpose({
    handleAccountEdition
  })
</script>

<template>
  <InstagramModalLinked
    v-if="showModalLinked"
    :from-challenge="fromChallenge"
    @close="showModalLinked = false"
    @edit="onEdit"
    @retry="setAccount($event)"
    @goto-challenge="onGoToChallenge"
  />
  <InstagramModalSearching
    v-if="showModalSearching"
    @error="onSearchingError"
    @success="onSearchingSuccess"
    @close="showModalSearching = false"
  />
  <InstagramModalNotLinked
    v-if="showModalNotLinked"
    :external-error="apiError"
    @submit="setAccount($event)"
    @close="showModalNotLinked = false"
  />
  <InstagramModalNotFound
    v-if="showModalNotFound"
    @close="showModalNotFound = false"
    @retry="setAccount($event)"
    @edit="onEdit"
  />
</template>
