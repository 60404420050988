<script setup lang="ts">
  const buttonReward = computed(() => useBrandingButton().extractButton('branding_step', 'reward_btn'))

  const { companyId } = useRoute().params
  const { data: claimedRewards } = useStoreReward().getClaimedRewards(parseInt(companyId as string))
  const { data: creator } = useStoreCreator().fetchCreator()
  const { hash } = useRoute()

  const buttonRewardText = computed(() => {
    return buttonReward.value.text.replace('{{rewards_redemeed}}', `${claimedRewards.value?.length ?? '0'}`)
  })

  const showModal = ref(false)

  onMounted(() => {
    if (hash.includes('#myrewards')) {
      showModal.value = true
    }
  })

  const onClick = () => {
    useTrack().track('click-rewards-claimed', {
      nbr_reward_claimed: claimedRewards.value?.length ?? 0,
      email: creator.value?.email ?? ''
    })
    showModal.value = true
  }
</script>

<template>
  <ButtonBranding
    :button-branding="buttonReward"
    class="btn-reward"
    @click="onClick"
  >
    {{ buttonRewardText }}
  </ButtonBranding>
  <SectionRewardsModalMyRewards
    :show="showModal"
    @close="showModal = false"
  />
</template>

<style scoped>
  @media (min-width: 768px) {
    .btn-reward {
      width: 320px;
    }
  }
</style>
