<script setup lang="ts">
  import type { Order } from '~/types/company'

  const storeStore = useStoreStore()
  const { t } = useI18n()

  const props = defineProps<{
    order: Order
  }>()

  const getOrderStatus = computed(() => {
    if (!props.order.is_point_allocated) {
      return t('SectionChallenges.ModalMissionLoyaltyDetail.orderWaiting')
    } else if (!props.order.is_paid) {
      return t('SectionChallenges.ModalMissionLoyaltyDetail.orderCancel')
    } else if (props.order.is_refund) {
      return t('SectionChallenges.ModalMissionLoyaltyDetail.orderRefund')
    } else {
      return t('SectionChallenges.ModalMissionLoyaltyDetail.orderPaid')
    }
  })
</script>

<template>
  <div class="order-item">
    <div class="order-item__order-id">
      <div class="text-primary-heading-2">
        {{ $t('SectionChallenges.ModalMissionLoyaltyDetail.orderNumber') }}
      </div>
      <div class="text-primary-heading-2">{{ order.external_id }}</div>
    </div>
    <div class="order-item__table">
      <div class="item">
        <div class="title">{{ $t('SectionChallenges.ModalMissionLoyaltyDetail.orderDate') }}</div>
        <div class="text-primary-heading-2">{{ $d(new Date(order.created_at), 'short') }}</div>
      </div>
      <div class="item">
        <div class="title">{{ $t('SectionChallenges.ModalMissionLoyaltyDetail.orderAmount') }}</div>
        <div class="text-primary-heading-2">
          {{
            $n(order.amount_ttc, {
              key: 'currency',
              currency: storeStore.store?.currency_code || defaultCurrency
            })
          }}
        </div>
      </div>
      <div class="item">
        <div class="title">{{ $t('SectionChallenges.ModalMissionLoyaltyDetail.orderRewardable') }}</div>
        <div class="text-primary-heading-2">
          {{
            $n(order.amount, {
              key: 'currency',
              currency: storeStore.store?.currency_code || defaultCurrency
            })
          }}
        </div>
      </div>
      <div class="item">
        <div class="title">{{ $t('SectionChallenges.ModalMissionLoyaltyDetail.orderPoints') }}</div>
        <div class="text-primary-heading-2">{{ order.is_point_allocated ? Math.ceil(order.points_value) : '' }}</div>
      </div>
      <div class="item">
        <div class="title">{{ $t('SectionChallenges.ModalMissionLoyaltyDetail.orderStatus') }}</div>
        <div class="text-primary-heading-2">{{ getOrderStatus }}</div>
      </div>
    </div>
  </div>
</template>
<style scoped>
  .order-item {
    border: 1px solid var(--gray-400);
    background: #fff;
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    margin-top: 10px;

    &__order-id {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    &__table {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      gap: 10px;
      @media (min-width: 768px) {
        flex-direction: row;
      }

      .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;

        @media (min-width: 768px) {
          flex-direction: column;
        }
      }
    }
  }
</style>
