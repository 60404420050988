<script setup lang="ts">
  import Pen from '@/assets/icons/pen.svg'
  const btnAction = computed(() => useBrandingButton().extractButton('branding_profil', 'action_btn'))

  defineProps<{
    fieldName: string
  }>()

  const emit = defineEmits<{
    submit: []
    edit: []
  }>()

  const isEdition = ref(false)

  const onEdit = () => {
    setIsEdition(true)
    emit('edit')
  }

  const onCancel = () => {
    setIsEdition(false)
  }

  const setIsEdition = (value: boolean) => {
    isEdition.value = value
  }

  defineExpose({
    setIsEdition
  })
</script>

<template>
  <div class="setting">
    <FormGroup>
      <FormLabel
        :for="fieldName"
        class="text-secondary-body setting--label"
      >
        <slot name="label" />
      </FormLabel>

      <div
        v-if="!isEdition"
        class="text-primary-heading-2 setting__readonly"
      >
        <slot name="readonly" />
        <button
          class="setting__readonly--button"
          @click="onEdit"
        >
          <Pen />
        </button>
      </div>
    </FormGroup>

    <form
      v-if="isEdition"
      class="setting__form"
      @submit.prevent="emit('submit')"
    >
      <slot name="form-content" />
      <div class="setting__form--buttons">
        <ButtonBranding
          :button-branding="btnAction"
          class="text-primary-heading-2"
          type="submit"
        >
          {{ $t('SectionProfile.buttons.save') }}
        </ButtonBranding>
        <ButtonBrandingInverted
          :button-branding="btnAction"
          class="text-primary-heading-2"
          @click="onCancel"
        >
          {{ $t('SectionProfile.buttons.cancel') }}
        </ButtonBrandingInverted>
      </div>
    </form>
  </div>
</template>

<style scoped>
  .setting {
    &--label {
      color: var(--gray-400);
    }
    &__readonly {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--button {
        cursor: pointer;
        padding-right: 0;
      }
    }
    &__form {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr auto;
      align-items: center;
      margin-top: 10px;

      &--buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        > button {
          width: auto;
        }
      }
    }
  }
</style>
