<script setup lang="ts">
  import type { ClaimedReward, Reward } from '~/types/reward'

  const { hash } = useRoute()
  const { isAuthenticated } = storeToRefs(useStoreAuth())
  const SectionName = 'FU-Rewards'
  const { getRewardFormattedText } = useFormattedText()

  const props = defineProps<{
    rewards: Reward[]
    anchorIgnore: boolean
  }>()

  const emit = defineEmits<{
    'open-anchor': []
  }>()

  const selectedReward = ref<Reward | null>(null)
  const selectedClaimedReward = ref<ClaimedReward | null>(null)
  const showRewardDetailModal = ref(false)
  const showRewardClaimModal = ref(false)
  const openFromAnchor = ref(false)

  // Navigate to reward detail if url hash like #reward-1
  onMounted(() => {
    if (props.anchorIgnore) return

    const hashFlag = '#reward-'
    if (hash.includes(hashFlag)) {
      const rewardId = +hash.split(hashFlag)[1]
      selectedReward.value = props.rewards.find((reward) => reward.id === rewardId) || null
      if (selectedReward.value !== null) {
        openFromAnchor.value = true
        showRewardDetailModal.value = true
        emit('open-anchor')
      }
    }
  })

  const onModalClose = () => {
    openFromAnchor.value = false
    showRewardDetailModal.value = false
    showRewardClaimModal.value = false
    selectedReward.value = null
  }

  const compareRewardPosition = (reward1: Reward, reward2: Reward) => {
    return reward1.position_index - reward2.position_index
  }

  const section = computed(() => {
    const { brandings } = useStoreBranding()

    if (!brandings) return

    const { extractTypography } = useBrandingTypography()
    const { extractButton } = useBrandingButton()

    return {
      card: brandings.branding_reward.card,
      rewardName: extractTypography('branding_reward', 'reward_text', 'primary', true),
      rewardPoints: extractTypography('branding_reward', 'point_text', 'secondary', true),
      actionButton: extractButton('branding_reward', 'action_btn')
    }
  })

  const rewardsSorted = computed((): Reward[] => {
    const rewards = props.rewards
    return rewards.sort(compareRewardPosition)
  })

  const onClick = (reward: Reward) => {
    if (isAuthenticated.value) {
      const { id, name, promo_type } = reward

      useTrack().track(SectionName, {
        reward_type: id,
        reward_name: name,
        reward_template: promo_type
      })
      selectedReward.value = reward
      showRewardDetailModal.value = true
    }
  }

  const onClaim = (claimedReward: ClaimedReward) => {
    selectedClaimedReward.value = claimedReward
    showRewardDetailModal.value = false
    showRewardClaimModal.value = true
  }
  const showModalLogin = ref(false)
  const showModalRegister = ref(false)
  const showModalForgottenPassword = ref(false)
  const showModalForgottenPasswordSuccess = ref(false)
  const requestNewPasswordEmail = ref('')

  const onLoginFromModal = () => {
    showModalRegister.value = false
    showModalLogin.value = true
  }

  const onRegisterFromModal = () => {
    showModalRegister.value = true
    showModalLogin.value = false
  }

  const onForgottenPasswordFromModal = () => {
    showModalLogin.value = false
    showModalRegister.value = false
    showModalForgottenPassword.value = true
  }

  const onSuccessPasswordForgotten = (email: string) => {
    showModalForgottenPassword.value = false
    requestNewPasswordEmail.value = email
    showModalForgottenPasswordSuccess.value = true
  }
</script>

<template>
  <template v-if="section">
    <CardReward
      v-for="reward in rewardsSorted"
      :id="reward.id"
      :key="reward.id"
      class="card-reward"
      :class="{ authenticated: isAuthenticated }"
      :background-color="section.card.background_color"
      :hover-background-color="section.card.hover_background_color"
      :border-color="section.card.border_color"
      :border-radius="section.card.radius"
      :reward-points="section.rewardPoints"
      :card-button="section.actionButton"
      :image-url="reward.picture_url_1"
      :reward-name="section.rewardName"
      @click="onClick(reward)"
    >
      <template #points>{{ $t('Points', reward.threshold) }}</template>
      <template #name>
        {{ getRewardFormattedText(reward.name, reward.amount, 'local_currency_reward') }}
      </template>
      <template #button>{{ section.actionButton.text }}</template>
      <template
        v-if="!isAuthenticated"
        #hover-content
      >
        <LoginOrRegister
          :track-section="SectionName"
          @login="showModalLogin = true"
          @register="showModalRegister = true"
        />
      </template>
    </CardReward>
    <SectionRewardsModalDetail
      v-if="selectedReward"
      :reward="selectedReward"
      :show="showRewardDetailModal"
      @close="showRewardDetailModal = false"
      @claim="onClaim"
    />
    <SectionRewardsModalClaim
      v-if="selectedClaimedReward"
      :claimed-reward="selectedClaimedReward"
      :show="showRewardClaimModal"
      @close="onModalClose"
    />
    <template v-if="!isAuthenticated">
      <ModalLogin
        v-if="showModalLogin"
        @close="showModalLogin = false"
        @register="onRegisterFromModal"
        @forgotten-password="onForgottenPasswordFromModal"
      />
      <ModalRegister
        v-if="showModalRegister"
        @close="showModalRegister = false"
        @login="onLoginFromModal"
      />
      <ModalPasswordForgotten
        v-if="showModalForgottenPassword"
        @close="showModalForgottenPassword = false"
        @success="onSuccessPasswordForgotten"
      />
      <ModalPasswordForgottenSuccess
        v-if="showModalForgottenPasswordSuccess"
        :email="requestNewPasswordEmail"
        @close="showModalForgottenPasswordSuccess = false"
      />
    </template>
  </template>
</template>

<style scoped>
  .card-reward {
    flex-shrink: 0;
  }
</style>
