<script setup lang="ts">
  import type { SocialAccountInfo } from '~/types/social-account'

  defineProps<{
    show: boolean
  }>()

  const emit = defineEmits<{
    close: []
    edit: []
  }>()

  const { data: creator } = useStoreCreator().fetchCreator()

  const account = computed((): SocialAccountInfo | undefined => {
    if (!creator.value) return

    return {
      profilePictureUrl: creator.value.tiktok_account.profile_picture_url,
      followersCount: creator.value.tiktok_account.followers_count,
      username: creator.value.tiktok_account.username,
      isPrivate: false
    }
  })

  const onEdit = () => {
    useTrack().track('click-social-media-verification', {
      social_media: 'tiktok',
      email: creator.value!.email
    })

    emit('edit')
  }

  const btn = computed(() => useBrandingButton().extractButton('branding_profil', 'action_btn'))
</script>

<template>
  <ModalApp
    :show
    footer-fixed
    @close="emit('close')"
  >
    <div class="tiktok-linked">
      <ModalSocialAccountTitle image-url="https://assets.loyoly.io/public/icons/tiktok.png">
        {{ $t('Tiktok.ModalLinked.title') }}
      </ModalSocialAccountTitle>

      <div class="tiktok-linked__account">
        <span class="text-primary-heading-2 tiktok-linked__account--title">
          {{ $t('Tiktok.ModalLinked.subtitle') }}
        </span>

        <ModalSocialAccountInfo
          v-if="account"
          :account
        />
      </div>

      <div class="tiktok-linked__description">
        <span class="text-primary-heading-2">
          {{ $t('Tiktok.ModalLinked.congratulations') }}
        </span>
        <p class="text-secondary-heading-2">
          {{ $t('Tiktok.ModalLinked.description') }}
        </p>
      </div>
    </div>

    <template #footer>
      <ButtonBrandingInverted
        :button-branding="btn"
        @click="onEdit"
      >
        {{ $t('Tiktok.ModalLinked.buttons.edit') }}
      </ButtonBrandingInverted>
      <ButtonBranding
        :button-branding="btn"
        @click="emit('close')"
      >
        {{ $t('Tiktok.ModalLinked.buttons.goback') }}
      </ButtonBranding>
    </template>
  </ModalApp>
</template>

<style scoped>
  .tiktok-linked {
    display: grid;
    gap: 30px;

    &__account {
      &--title {
        display: block;
        margin-bottom: 10px;
      }
    }

    &__description {
      display: grid;
      gap: 10px;
    }
  }
</style>
