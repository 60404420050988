<script setup lang="ts">
  import type { ConcreteComponent } from 'vue'
  import type { Campaign } from '~/types/campaign'

  const SectionName = 'FU_Product_Test'
  const dayjs = useDayjs()
  const { t } = useI18n()
  const { hash } = useRoute()
  const { isAuthenticated } = storeToRefs(useStoreAuth())
  const { companyId } = useRoute().params
  const { data: applications } = useStoreCampaign().getApplications(parseInt(companyId as string))

  const props = defineProps<{
    giftings: Campaign[]
    anchorIgnore: boolean
  }>()

  const emit = defineEmits<{
    'open-anchor': []
  }>()

  const compareGiftingPosition = (gifting1: Campaign, gifting2: Campaign) => {
    return gifting1.position_index - gifting2.position_index
  }

  const section = computed(() => {
    const { brandings } = useStoreBranding()

    if (!brandings?.branding_gifting) return

    const { extractTypography } = useBrandingTypography()
    const { extractButton } = useBrandingButton()

    return {
      card: brandings.branding_gifting.card,
      sectionColor: brandings.branding_gifting.section_color,
      actionButton: extractButton('branding_gifting', 'action_btn'),
      title: extractTypography('branding_gifting', 'section_title', 'primary'),
      text: extractTypography('branding_gifting', 'gifting_text', 'secondary', true)
    }
  })

  const giftingCampaignsSorted = computed((): Campaign[] => {
    return [...props.giftings].sort(compareGiftingPosition)
  })

  const getApplicationsForGifting = (gifting: Campaign) => {
    if (!applications.value) return []
    return applications.value.filter((application) => {
      return application.campaign === gifting.id
    })
  }

  const getStatusText = (gifting: Campaign, textForApply: string) => {
    if (gifting.can_participate) {
      return t(`SectionGiftingCardList.status.participate`)
    }

    if (getApplicationsForGifting(gifting).length > 0) {
      return t(`SectionGiftingCardList.status.underValidation`)
    }

    if (dayjs(gifting.end_date).isBefore(dayjs())) {
      return t(`SectionGiftingCardList.status.ended`)
    }

    if (gifting.can_participate === null) {
      return t(`SectionGiftingCardList.status.rejected`)
    }

    return textForApply
  }

  const canApply = (gifting: Campaign) => {
    const { can_participate, end_date } = gifting

    return !can_participate && !dayjs(end_date).isBefore(dayjs()) && getApplicationsForGifting(gifting).length === 0
  }

  const isUnderValidation = (gifting: Campaign) => {
    return !gifting.can_participate && getApplicationsForGifting(gifting).length > 0
  }

  const btnDisabled = (gifting: Campaign) => {
    const canSee = gifting.can_participate !== null
    if (gifting.can_participate === true) {
      return false
    }
    return !canSee || dayjs(gifting.end_date).isBefore(dayjs())
  }

  const selectedGifting = ref<Campaign | null>(null)
  const showModal = ref(false)
  const modalComponent = shallowRef<ReturnType<typeof resolveComponent> | null>(null)
  const openFromAnchor = ref(false)

  onMounted(() => {
    if (props.anchorIgnore) return

    const hashFlag = '#gifting-'
    if (hash.includes(hashFlag)) {
      const giftingId = +hash.split(hashFlag)[1]
      selectedGifting.value = props.giftings.find((gifting) => gifting.id === giftingId) || null
      if (selectedGifting.value !== null) {
        emit('open-anchor')
        modalComponent.value = getModalComponent(selectedGifting.value) as ConcreteComponent
        showModal.value = true
      }
    }
  })

  const onModalClose = () => {
    openFromAnchor.value = false
    showModal.value = false
    selectedGifting.value = null
  }

  const onClick = (gifting: Campaign) => {
    if (isAuthenticated.value) {
      useTrack().track('click-product-test-details')
    }
    selectedGifting.value = gifting
    showModal.value = true
    modalComponent.value = getModalComponent(gifting) as ConcreteComponent
  }

  const getModalComponent = (gifting: Campaign) => {
    if (gifting.can_participate) {
      return resolveComponent('LazySectionGiftingModalParticipate')
    }
    if (canApply(gifting)) {
      return resolveComponent('LazySectionGiftingModalApply')
    }
    if (isUnderValidation(gifting)) {
      return resolveComponent('LazySectionGiftingModalUnderValidation')
    }
    return
  }

  provide('actionButtonBranding', section.value!.actionButton)

  // login or register
  const showModalLogin = ref(false)
  const showModalRegister = ref(false)
  const showModalForgottenPassword = ref(false)
  const showModalForgottenPasswordSuccess = ref(false)
  const requestNewPasswordEmail = ref('')

  const onLoginFromModal = () => {
    showModalRegister.value = false
    showModalLogin.value = true
  }

  const onRegisterFromModal = () => {
    showModalRegister.value = true
    showModalLogin.value = false
  }

  const onForgottenPasswordFromModal = () => {
    showModalLogin.value = false
    showModalRegister.value = false
    showModalForgottenPassword.value = true
  }

  const onSuccessPasswordForgotten = (email: string) => {
    showModalForgottenPassword.value = false
    requestNewPasswordEmail.value = email
    showModalForgottenPasswordSuccess.value = true
  }
</script>

<template>
  <template v-if="section">
    <CardGifting
      v-for="gifting in giftingCampaignsSorted"
      :id="gifting.id"
      :key="gifting.id"
      class="card-gifting"
      :class="{ authenticated: isAuthenticated }"
      :background-color="section.card.background_color"
      :hover-background-color="section.card.hover_background_color"
      :border-color="section.card.border_color"
      :border-radius="section.card.radius"
      :card-button="{ ...section.actionButton, disabled: btnDisabled(gifting) }"
      :image-url="gifting.picture_url"
      :gifting-name="section.text"
      @click="onClick(gifting)"
    >
      <template #name>
        {{ gifting.tag }}
      </template>
      <template #button>
        {{ getStatusText(gifting, section.actionButton.text) }}
      </template>
      <template
        v-if="!isAuthenticated"
        #hover-content
      >
        <LoginOrRegister
          :track-section="SectionName"
          @login="showModalLogin = true"
          @register="showModalRegister = true"
        />
      </template>
    </CardGifting>
    <component
      :is="modalComponent"
      v-if="showModal && selectedGifting"
      :gifting="selectedGifting"
      @close="onModalClose"
    />

    <template v-if="!isAuthenticated">
      <ModalLogin
        v-if="showModalLogin"
        @close="showModalLogin = false"
        @register="onRegisterFromModal"
        @forgotten-password="onForgottenPasswordFromModal"
      />
      <ModalRegister
        v-if="showModalRegister"
        @close="showModalRegister = false"
        @login="onLoginFromModal"
      />
      <ModalPasswordForgotten
        v-if="showModalForgottenPassword"
        @close="showModalForgottenPassword = false"
        @success="onSuccessPasswordForgotten"
      />
      <ModalPasswordForgottenSuccess
        v-if="showModalForgottenPasswordSuccess"
        :email="requestNewPasswordEmail"
        @close="showModalForgottenPasswordSuccess = false"
      />
    </template>
  </template>
</template>

<style scoped>
  .card-gifting {
    flex-shrink: 0;
  }
</style>
