import { onMounted, onUnmounted, reactive, toRefs } from 'vue'

export function useMonitorSize() {
  const observer = ref<ResizeObserver | null>(null)

  const sizes = reactive({
    browserWidth: window.innerWidth,
    deviceWidth: screen.width,
    isMobile: false
  })

  const browserResized = () => {
    sizes.browserWidth = window.innerWidth
    sizes.deviceWidth = screen.width
    sizes.isMobile = isMobile()
  }

  const isMobile = () => {
    return window.innerWidth <= 768 ? true : false // TODO: variabiliser avec breakpoints css
  }

  onMounted(() => {
    browserResized()
    observer.value = new ResizeObserver(browserResized)
    observer.value.observe(document.body)
  })

  onUnmounted(() => {
    observer.value?.disconnect()
  })

  return {
    ...toRefs(sizes)
  }
}
