<template>
  <LoaderSkeleton>
    <div class="vip-card-skeleton" />
  </LoaderSkeleton>
</template>

<style scoped>
  .vip-card-skeleton {
    width: 150px;
    height: 222px;
    background-color: #e1e1e1;

    @media (min-width: 768px) {
      width: 320px;
      height: 380px;
    }
  }
</style>
