<template>
  <LoaderSkeleton>
    <div class="challenge-card-skeleton" />
  </LoaderSkeleton>
</template>

<style scoped>
  .challenge-card-skeleton {
    width: 150px;
    height: 222px;
    background-color: #e1e1e1;

    @media (min-width: 768px) {
      width: 300px;
      height: 300px;
    }
  }
</style>
