<script setup lang="ts">
  import { useField, useForm } from 'vee-validate'
  import { toTypedSchema } from '@vee-validate/zod'
  import * as zod from 'zod'
  import { useI18n } from 'vue-i18n'

  const props = defineProps<{
    externalError: string | undefined
  }>()

  const emit = defineEmits<{
    close: []
    submit: [username: string]
  }>()

  const { t } = useI18n()

  const validationSchema = toTypedSchema(
    zod.object({
      username: zod
        .string({ required_error: t('Instagram.ModalNotLinked.errors.usernameEmpty') })
        .min(1, { message: t('Instagram.ModalNotLinked.errors.usernameEmpty') })
    })
  )

  const { handleSubmit, errors } = useForm({ validationSchema })
  const { value: username } = useField('username')

  const { data: creator } = useStoreCreator().fetchCreator()

  const onSubmit = handleSubmit(async ({ username }) => {
    useTrack().track('click-social-media-verification', {
      social_media: 'instagram',
      email: creator.value!.email
    })
    emit('submit', username)
  })

  const btn = computed(() => useBrandingButton().extractButton('branding_profil', 'action_btn'))

  const { modalsOpenedCount, isModalFullPage, isMobile } = storeToRefs(useStoreApp())

  onMounted(() => {
    if (!props.externalError && creator.value?.insta_account) {
      username.value = creator.value.insta_account.insta_username
    }
  })
</script>

<template>
  <ModalApp
    show
    :class="{ 'child-modal': modalsOpenedCount > 0 && !isModalFullPage && !isMobile }"
    footer-fixed
    @close="emit('close')"
  >
    <div class="instagram-not-linked">
      <ModalSocialAccountTitle image-url="https://assets.loyoly.io/public/icons/instagram.png">
        {{ $t('Instagram.ModalNotLinked.title') }}
      </ModalSocialAccountTitle>

      <span class="text-primary-heading-2">
        {{ $t('Instagram.ModalNotLinked.subtitle') }}
      </span>

      <p class="text-secondary-heading-2">
        {{ $t('Instagram.ModalNotLinked.description') }}
      </p>
      <form
        class="instagram-not-linked__form"
        @submit="onSubmit"
      >
        <FormGroup>
          <FormLabel for="username">
            {{ $t('Instagram.ModalNotLinked.form.username') }}
          </FormLabel>
          <FormInputText
            v-model="username"
            class="instagram-not-linked__form--username"
            name="username"
            :error-message="errors.username || externalError"
            :placeholder="$t('Instagram.ModalNotLinked.form.usernameExample')"
          />
        </FormGroup>
      </form>
    </div>

    <template #footer>
      <ButtonBranding
        :button-branding="btn"
        type="submit"
        @click="onSubmit"
      >
        {{ $t('Instagram.ModalNotLinked.button') }}
      </ButtonBranding>
    </template>
  </ModalApp>
</template>

<style scoped>
  .instagram-not-linked {
    display: grid;
    gap: 20px;
    &__form--username {
      width: 100%;
    }
  }

  .child-modal {
    :deep(.lds-modal__container) {
      left: unset;
      top: unset;
      transform: unset;
    }
  }
</style>
