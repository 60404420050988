import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import type { Campaign, Application, Recruitment, ApplyApplicationForm } from '~/types/campaign'

export const useStoreCampaign = defineStore('campaign', () => {
  const { isAuthenticated } = storeToRefs(useStoreAuth())

  const getCampaigns = (companyId: number) => {
    return useQuery({
      queryKey: ['listCampaigns', companyId],
      queryFn: async () => {
        return (await useNuxtApp().$api(`/api/creator/company/${companyId}/campaigns/`)) as Campaign[]
      },
      staleTime: 1800000 // 30 minutes
    })
  }

  const getApplications = (companyId: number) => {
    return useQuery({
      queryKey: ['listApplications', companyId],
      queryFn: async () => {
        return (await useNuxtApp().$api(`/api/creator/companies/${companyId}/applications/`)) as Application[]
      },
      enabled: isAuthenticated.value,
      staleTime: 1800000 // 30 minutes
    })
  }

  const getRecruitments = (companyId: number, programId: number) => {
    return useQuery({
      queryKey: ['listRecruitments', companyId, programId],
      queryFn: async () => {
        return (await useNuxtApp().$api(`/api/creator/companies/${companyId}/recruitments/`, {
          params: { campaign_id: programId }
        })) as Recruitment[]
      },
      enabled: isAuthenticated.value,
      staleTime: 1800000 // 30 minutes
    })
  }

  const queryClient = useQueryClient()

  const applyApplication = () => {
    return useMutation({
      mutationFn: async (applyForm: ApplyApplicationForm) => {
        const { companyId, data } = applyForm
        await useNuxtApp().$api(`/api/creator/companies/${companyId}/applications/`, {
          method: 'POST',
          body: {
            ...data,
            company: companyId
          }
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['listApplications'] })
      }
    })
  }

  return { getCampaigns, getApplications, getRecruitments, applyApplication }
})
