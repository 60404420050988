<script setup lang="ts">
  const { extractTypography } = useBrandingTypography()

  const extractStep = (stepIndex: number) => {
    return {
      stepNumber: extractTypography('branding_step', `title_step_${stepIndex}`, 'primary'),
      stepTitle: extractTypography('branding_step', `text_step_${stepIndex}`, 'primary'),
      stepDescription: extractTypography('branding_step', `description_step_${stepIndex}`, 'secondary')
    }
  }

  const stepsNotConnected = computed(() => {
    return [extractStep(1), extractStep(2), extractStep(3)]
  })
</script>

<template>
  <div class="section-steps">
    <StepNotConnected
      v-for="(step, idx) in stepsNotConnected"
      :key="`steps_#${idx}`"
      :step-number="step.stepNumber"
      :step-title="step.stepTitle"
      :step-description="step.stepDescription"
    >
      <template #number>{{ step.stepNumber.text }}</template>
      <template #title>{{ step.stepTitle.text }}</template>
      <template #description>{{ step.stepDescription.text }}</template>
    </StepNotConnected>
  </div>
</template>

<style scoped>
  .section-steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 40px;
    }
  }
</style>
